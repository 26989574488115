<template>
    <div class="onboarding-profile">
        <StackRouterHeaderBar
            v-if="currentField.title === 'personal_story'"
            :title="'프로필 작성'"
            :show-title="true"
            :leftButtonHandler="leftButtonHandler"
            :rightButtonHandler="onClose"
        />
        <StackRouterHeaderBar
            v-else
            :title="'프로필 작성'"
            :show-title="true"
            :rightButton="'close'"
            :leftButtonHandler="leftButtonHandler"
            :rightButtonHandler="onClose"
        />
        <component
            :is="componentByProfileSteps"
            v-bind="[currentProps, editProps, stageProps]"
            ref="component"
            @photo="receivePhoto"
            @disabled="updateDisabled"
            @jobCategory="getJobCategory"
            @onChangeDeletePhotoIds="onChangeDeletePhotoIds"
            @close="trigger"
        />
        <BottomButton v-show="ctaActive" @click="trigger" v-bind="bottomButtonProps" :disabled="disabled" />
    </div>
</template>

<script>
import profileService from '@/services/profile'

export default {
    components: {
        EditPhotos: () => import('@/routes/my-page/components/EditPhotos'),
        School: () => import('@/components/profile/School'),
        ItemSelector: () => import('@/components/profile/ItemSelector'),
        ItemSteps: () => import('@/components/profile/ItemSteps'),
        Health: () => import('@/components/profile/Health'),
        JobTypeA: () => import('@/components/profile/JobTypeA'),
        JobTypeB: () => import('@/components/profile/JobTypeB'),
        Finance: () => import('@/components/profile/Finance'),
        SiblingRelation: () => import('@/components/profile/SiblingRelation'),
        ParentRelation: () => import('@/components/profile/ParentRelation'),
        Family: () => import('@/components/profile/Family'),
        Intro: () => import('@/components/profile/Intro'),
        EmploymentType: () => import('@/components/profile/EmploymentType'),
        House: () => import('@/components/profile/House'),
        Sibling: () => import('@/components/profile/Sibling'),
        PersonalStory: () => import('@/components/profile/PersonalStory'),
    },
    name: 'ProfileSteps',
    data: () => ({
        disabled: true,
        job_category: null,
        deletePhotoIds: [],
        editProps: {},
        jobType: null,
        lastStage: 0,
    }),
    mounted() {
        this.init()
    },
    created() {
        this.$registerBackHandler(this.leftButtonHandler)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
    computed: {
        stageProps() {
            return { stage: this.stage }
        },
        ctaActive() {
            const fieldWithCTA = [
                'height',
                'health_status',
                // 'bachelor',
                // 'master',
                // 'doctor',
                'jobDetail',
                'income',
                'asset',
                'brother_order',
                'family_asset',
                'father',
                'mother',
                'photo_upload',
                'intro',
                'only_to_manager',
                'car_model',
                'sibling',
                'companyNameModified',
            ]

            return fieldWithCTA.indexOf(this.currentField.title) > -1 ? true : false
        },
        me() {
            return this.$store.getters.me
        },
        profile() {
            return this.$store.getters.profile
        },
        items() {
            return this.$store.getters.items
        },
        schoolId() {
            return this.$store.getters.profile.school_id || 0
        },
        noCar() {
            return this.$store.getters.profile.profile_options.some(o => Number(o.profile_option_id) === 205)
        },
        photo: {
            get() {
                return this.$store.getters.me.photos || ''
            },
            set(photo) {
                this.$set(this.me, 'photos', photo)
            },
        },
        stage: {
            get() {
                return this.$store.getters.profileStep
            },
            set(step) {
                const dom = document.querySelector('.onboarding-profile')
                dom.scrollTo(0, 0)
                window.localStorage.setItem('profileStep', Number(step))

                this.$store.commit('setProfileStep', step)
            },
        },

        doubleBtnsInfo() {
            const next = {
                style: {
                    backgroundColor: 'none',
                    color: '#111111',
                    border: 'solid 1px #B9BDC1',
                },
                title: '나중에 하기',
                click: this.pass,
            }
            const disabled = {
                style: {
                    backgroundColor: '#F7F8F9',
                    color: '#B9BDC1',
                    border: 'none',
                },
                title: '다음',
                click: this.trigger,
            }
            const save = {
                style: {
                    backgroundColor: '#070A0E',
                    color: '#FFFFFF',
                    border: 'none',
                },
                title: '다음',
                click: this.trigger,
            }

            if (this.disabled) {
                return [next, disabled]
            }
            return [next, save]
        },
        bottomButtonProps() {
            // if (['master', 'doctor', 'intro'].indexOf(this.currentField.title) > -1) {
            // if (['intro'].indexOf(this.currentField.title) > -1) {
            //     return { doubleBtn: true, doubleBtnsInfo: this.doubleBtnsInfo }
            // } else {
            console.log('stage', this.stage)
            if (this.stage === this.lastStage) {
                return { label: '저장', doubleBtn: false, blackBtn: true }
            }
            return { label: '다음', doubleBtn: false, blackBtn: true }
            // }
        },
        componentByProfileSteps() {
            // 키
            if (this.currentField.title === 'height') return 'ItemSelector'
            // 지역
            if (this.currentField.title === 'state') return 'ItemSteps'
            if (this.currentField.title === 'mbti') return 'ItemSelector'
            // 흡연
            if (this.currentField.title === 'smoking') return 'ItemSelector'
            if (this.currentField.title === 'religion') return 'ItemSelector'
            if (this.currentField.title === 'schools') return 'ItemSelector'
            if (this.currentField.title === 'bachelor') return 'ItemSelector'
            if (this.currentField.title === 'bachelor_college') return 'ItemSelector'
            if (this.currentField.title === 'bachelor_major') return 'ItemSelector'
            // 석/박사 추가
            if (this.currentField.title === 'master') return 'ItemSelector'
            if (this.currentField.title === 'master_college') return 'ItemSelector'
            if (this.currentField.title === 'master_major') return 'ItemSelector'
            if (this.currentField.title === 'doctor') return 'ItemSelector'
            if (this.currentField.title === 'doctor_college') return 'ItemSelector'
            if (this.currentField.title === 'doctor_major') return 'ItemSelector'
            // 직장 직업 분류
            if (this.currentField.title === 'job_category') return 'ItemSelector'
            // 회사명
            if (this.currentField.title === 'company') return 'ItemSelector'
            if (this.currentField.title === 'companyNameModified') return 'Intro'
            if (this.currentField.title === 'jobDetail') return 'Intro'
            if (this.currentField.title === 'employment_type') return 'EmploymentType'
            if (this.currentField.title === 'company_state') return 'ItemSteps'

            if (this.currentField.title === 'income') return 'Finance'
            if (this.currentField.title === 'asset') return 'Finance'
            // 하우스
            if (this.currentField.title === 'house_style') return 'House'
            if (this.currentField.title === 'house_payment') return 'House'
            if (this.currentField.title === 'car') return 'ItemSteps'
            if (this.currentField.title === 'car_model') return 'Intro'

            if (this.currentField.title === 'brother_order') return 'SiblingRelation'
            if (this.currentField.title === 'parent_status') return 'ParentRelation'
            if (this.currentField.title === 'family_asset') return 'Finance'
            if (this.currentField.title === 'father') return 'Family'
            if (this.currentField.title === 'mother') return 'Family'
            if (this.currentField.title === 'sibling') return 'Sibling'
            if (this.currentField.title === 'marry_plan') return 'ItemSelector'
            if (this.currentField.title === 'marry') return 'ItemSelector'
            if (this.currentField.title === 'child_plan') return 'ItemSelector'
            if (this.currentField.title === 'career_plan') return 'ItemSelector'
            if (this.currentField.title === 'photo_upload') return 'EditPhotos'
            if (this.currentField.title === 'intro') return 'Intro'
            if (this.currentField.title === 'only_to_manager') return 'Intro'
            // 퍼스널 스토리
            if (this.currentField.title === 'personal_story') return 'PersonalStory'
            return ''
        },
        currentProps() {
            if (this.currentField.title === 'height') return { options: { model: 'height' } }
            if (this.currentField.title === 'state') return { options: { model: 'state', title: 'state' } }
            if (this.currentField.title === 'mbti') return { options: { model: 'mbti', title: 'mbti' } }
            if (this.currentField.title === 'smoking') return { options: { model: 'smoking' } }
            if (this.currentField.title === 'religion') return { options: { model: 'religion' } }
            if (this.currentField.title === 'health_status') return { type: 'health_status' }
            if (this.currentField.title === 'schools') return { options: { model: 'schools', title: 'edu_background' } }
            if (this.currentField.title === 'bachelor')
                return { options: { model: 'university', title: 'bachelor_university' } }
            if (this.currentField.title === 'bachelor_college')
                return { options: { model: 'college', title: 'bachelor_college' } }
            if (this.currentField.title === 'bachelor_major')
                return { options: { model: 'major', title: 'bachelor_major' } }
            if (this.currentField.title === 'master')
                return { options: { model: 'university', title: 'master_university' } }
            if (this.currentField.title === 'master_college')
                return { options: { model: 'college', title: 'master_college' } }
            if (this.currentField.title === 'master_major')
                return { options: { model: 'major', title: 'master_major' } }
            if (this.currentField.title === 'doctor')
                return { options: { model: 'university', title: 'doctor_university' } }
            if (this.currentField.title === 'doctor_college')
                return { options: { model: 'college', title: 'doctor_college' } }
            if (this.currentField.title === 'doctor_major')
                return { options: { model: 'major', title: 'doctor_major' } }
            // 직장직업 분류
            if (this.currentField.title === 'job_category') return { options: { model: 'jobCategory' } }
            if (this.currentField.title === 'company') return { options: { model: 'company' } }
            if (this.currentField.title === 'companyNameModified')
                return {
                    type: 'companyNameModified',
                    section: 'Personal',
                    inputMode: true,
                }
            if (this.currentField.title === 'jobDetail') {
                if (this.jobType === 'A') {
                    return {
                        type: 'job',
                        section: 'Personal',
                        inputMode: true,
                        job_category: this.job_category,
                        jobType: this.jobType,
                    }
                } else if (this.jobType === 'C') {
                    return {
                        type: 'job',
                        section: 'Personal',
                        inputMode: false,
                        job_category: this.job_category,
                        jobType: this.jobType,
                    }
                }
            }
            if (this.currentField.title === 'company_state')
                return { options: { model: 'state', title: 'company_state' } }
            if (this.currentField.title === 'income') return { type: 'income', section: 'Financial' }
            if (this.currentField.title === 'asset') return { type: 'asset', section: 'Financial' }
            // house
            if (this.currentField.title === 'house_style') return { type: 'house_style', section: 'House' }
            if (this.currentField.title === 'house_payment') return { type: 'house_payment', section: 'House' }
            if (this.currentField.title === 'car') return { options: { model: 'car', title: 'car' } }
            if (this.currentField.title === 'car_model')
                return { type: 'car_model', section: 'Financial', inputMode: true }

            if (this.currentField.title === 'brother_order') return { type: 'family_relations' }
            if (this.currentField.title === 'parent_status') return { type: 'parent_status' }
            if (this.currentField.title === 'family_asset') return { type: 'family_asset', section: 'Financial' }
            if (this.currentField.title === 'father') return { familyType: { id: 0, name: 'father' } }
            if (this.currentField.title === 'mother') return { familyType: { id: 1, name: 'mother' } }
            if (this.currentField.title === 'sibling') return { familyType: { id: 2, name: 'sibling' } }
            if (this.currentField.title === 'marry_plan') return { options: { model: 'marry_plan' } }
            if (this.currentField.title === 'marry') return { options: { model: 'marry' } }
            if (this.currentField.title === 'child_plan') return { options: { model: 'child_plan' } }
            if (this.currentField.title === 'career_plan') return { options: { model: 'career_plan' } }
            if (this.currentField.title === 'photo_upload') return { value: this.photo, showTitle: true }
            if (this.currentField.title === 'intro') return { type: 'intro', section: 'Personal' }
            if (this.currentField.title === 'only_to_manager') return { type: 'onlymanager', section: 'Personal' }

            if (this.currentField.title === 'personal_story')
                return { options: { model: 'personalStory', title: 'personal_story' } }
            return ''
        },

        stages() {
            return [
                {
                    id: 1,
                    title: 'height',
                },
                {
                    id: 2,
                    title: 'state',
                },
                {
                    id: 3,
                    title: 'religion',
                },
                {
                    id: 4,
                    title: 'smoking',
                },
                {
                    id: 5,
                    title: 'mbti',
                },
                {
                    id: 6,
                    title: 'schools',
                },
                {
                    id: 7,
                    title: 'bachelor',
                },
                {
                    id: 8,
                    title: 'bachelor_major',
                },
                {
                    id: 9,
                    title: 'master',
                },
                {
                    id: 10,
                    title: 'master_major',
                },
                {
                    id: 11,
                    title: 'doctor',
                },
                {
                    id: 12,
                    title: 'doctor_major',
                },
                {
                    id: 13,
                    title: 'job_category',
                },
                {
                    id: 14,
                    title: 'company',
                },
                {
                    id: 15,
                    title: 'jobDetail',
                },
                {
                    id: 16,
                    title: 'employment_type',
                },
                {
                    id: 17,
                    title: 'company_state',
                },
                {
                    id: 18,
                    title: 'income',
                },
                {
                    id: 19,
                    title: 'asset',
                },
                {
                    id: 20,
                    title: 'marry_plan',
                },
                {
                    id: 21,
                    title: 'marry',
                },
                {
                    id: 22,
                    title: 'photo_upload',
                },
                {
                    id: 23,
                    title: 'personal_story',
                },
            ]
        },
        currentField() {
            return this.stages.filter(s => s.id === this.stage)[0]
        },
    },
    watch: {
        stage() {
            this.firebaseEvent()
        },
    },
    methods: {
        firebaseEvent() {
            let eventName = ''
            // if (this.currentField.title === 'health_status') {
            //     eventName = 'health'
            // } else if (this.currentField.title === 'marry_plan') {
            //     eventName = 'marriage_plan'
            // } else if (this.currentField.title === 'child_plan') {
            //     eventName = 'children_plan'
            // } else if (this.currentField.title === 'career_plan') {
            //     eventName = 'career_plan'
            // } else if (this.currentField.title === 'only_to_manager') {
            //     eventName = 'to_manager'
            // } else {
            eventName = this.currentField.title
            // }
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_${this.$case.toPascal(eventName)}Page_After`,
                },
            })
        },
        async init() {
            this.$store.dispatch('loadProfile')
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })

            this.lastStage = this.stages.length
            this.firebaseEvent()
            try {
                this.checkJobCategory()
                const step = Number(window.localStorage.getItem('profileStep'))
                if (step === 15) {
                    // company
                    this.$store.commit('setProfileStep', Number(step))
                    // this.$store.commit('setProfileStep', 16)
                } else if (step) {
                    this.$store.commit('setProfileStep', Number(step))
                } else {
                    this.$store.commit('setProfileStep', this.setInitialStage())
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        pass() {
            this.stage++
        },
        async trigger() {
            const child = this.$refs.component
            const res = await child.onSave(this.currentField.title)
            if (res) return
            await this.saveProfile()
            if (this.stage === this.lastStage) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `Complete_ProfileInput`,
                    },
                })
                this.$stackRouter.pop()
            } else {
                this.moveForward()
            }

            this.disabled = true
        },
        moveForward() {
            if (
                (this.currentField.title === 'schools' && this.schoolId === 9) ||
                (this.currentField.title === 'bachelor_major' && this.schoolId > 4 && this.schoolId < 9) ||
                (this.currentField.title === 'master_major' && this.schoolId > 2)
            ) {
                const stageToMove = this.stages.find(s => s.title === 'job_category')
                this.stage = stageToMove.id
            } else if (this.currentField.title === 'job_category' && this.jobType === 'B') {
                // 'B' detail no필요
                const stageToMove = this.stages.find(s => s.title === 'income')
                this.stage = stageToMove.id
                // } else if (this.currentField.title === 'job_category' && this.jobType === 'C') {
                //     // 'C' detail필요
                //     const stageToMove = this.stages.find(s => s.title === 'jobDetail')
                //     this.stage = stageToMove.id
            } else if (this.currentField.title === 'car' && this.noCar) {
                this.stage = this.stage + 2
            } else {
                this.stage++
            }
        },
        moveBack() {
            if (this.currentField.title === 'income') {
                if (this.jobType === 'B') {
                    const stageToMove = this.stages.find(s => s.title === 'job_category')
                    this.stage = stageToMove.id
                } else {
                    this.stage--
                }
            } else if (this.currentField.title === 'jobDetail') {
                const stageToMove = this.stages.find(s => s.title === 'job_category')
                if (this.jobType === 'C') {
                    this.stage = stageToMove.id
                } else {
                    this.stage--
                }
            } else if (this.currentField.title === 'job_category') {
                if (this.schoolId === 9) {
                    // 최종학력 고등인경우
                    const stageToMove = this.stages.find(s => s.title === 'schools')
                    this.stage = stageToMove.id
                } else if (this.schoolId > 4 && this.schoolId < 9) {
                    // 최종학력 학사
                    const stageToMove = this.stages.find(s => s.title === 'bachelor_major')
                    this.stage = stageToMove.id
                } else if (this.schoolId > 2) {
                    // 최종학력 석사
                    const stageToMove = this.stages.find(s => s.title === 'master_major')
                    this.stage = stageToMove.id
                } else {
                    // 최종학력 박사
                    this.stage--
                }
            } else if (this.currentField.title === 'brother_order' && this.noCar) {
                this.stage = this.stage - 2
            } else {
                this.stage--
            }
        },
        async onClose() {
            const idx = await this.$modal.basic({
                title: '프로필 작성을 그만하시겠습니까?',
                body: '현재 작성한 부분까지 임시저장됩니다.',
                showCloseButton: false,
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                const child = this.$refs.component
                const res = await child.onSave(this.currentField.title)
                if (res) {
                    this.$toast.success('입력하신 정보를 임시저장했습니다!')
                    this.$stackRouter.pop()
                    return
                }
                await this.saveProfile()
                this.$stackRouter.pop()
                // this.$stackRouter.push({ name: 'ChatsPage' })
            }
        },
        receivePhoto(photo) {
            this.photo = photo
        },
        leftButtonHandler() {
            if (this.stage === 1) {
                this.$stackRouter.pop()
            } else {
                this.moveBack()
            }
        },
        updateDisabled(disabled) {
            this.disabled = disabled
        },
        checkJobCategory() {
            if (this.profile.company.length) {
                const profileItems = this.$store.getters.jobCategories
                profileItems.forEach(item => {
                    if (item.id === this.profile.company[0].job_category_id) {
                        this.getJobCategory(item)
                    }
                })
            }
        },
        getJobCategory(jobCategory) {
            this.job_category = jobCategory
            if (this.$profile.jobFreePage(jobCategory.id)) {
                const needDetail = [12, 13, 14]
                if (needDetail.indexOf(jobCategory.id) > -1) {
                    this.jobType = 'C'
                } else {
                    this.jobType = 'B'
                }
            } else {
                this.jobType = 'A'
            }
        },
        onChangeDeletePhotoIds(deletePhotoIds) {
            this.deletePhotoIds = deletePhotoIds
        },
        async saveProfile() {
            try {
                this.$loading(true)
                const { first_complete_profile: complete } = await profileService.update(this.preparedPayload())
                this.$loading(false)
                if (complete) {
                    // this.$store.dispatch('loadProfile')
                    this.$store.dispatch('loadMe')
                }
                this.$toast.success('입력하신 정보를 임시저장했습니다!')
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
                this.$loading(false)
            }
        },
        preparedPayload() {
            const keys = [
                'height',
                'state',
                'smoking',
                'religion',
                'health_status',
                'bachelor',
                'master',
                'doctor',
                'company',
                'income',
                'asset',
                'family_relations', // 형제관계
                'parent_status',
                'family_asset',
                'family',
                'marry_plan',
                'marry',
                'child_plan',
                'career_plan',
                'intro',
                'only_to_manager',
                'school_id',
                'house_style',
                'car_model',
            ]
            const form = new FormData()
            form.append('id', this.profile.id)
            keys.forEach(key => {
                const snake = this.$case.toSnake(key)
                if (this.profile[snake] === null) return

                if (snake === 'smoking') {
                    form.append('smoking', this.profile.smoking ? '1' : '0')
                    return
                }

                if (this.$profile.needProfileOption(snake)) return

                // if (snake === 'car') {
                //     if (this.profile.car) {
                //         form.append('car_model', this.profile.car_model)
                //         return
                //     } else {
                //         form.append('car_model', '')
                //     }
                // }

                // if (snake === 'jobDescription' && this.profile.job_description) {
                //     form.append('job_description', this.profile.job_description)
                //     return
                // }

                if (snake === 'state' || snake === 'hometown') {
                    try {
                        const prefix = snake !== 'state' ? `${snake}_` : ''
                        form.append(`${prefix}state_id`, this.profile[`${prefix}state`].id)
                        if (this.profile[`${prefix}state`].is_station) {
                            form.append(`${prefix}station_id`, this.profile[`${prefix}station`].id)
                        } else {
                            form.append(`${prefix}region_id`, this.profile[`${prefix}region`].id)
                        }
                    } catch (e) {
                        return
                    }

                    return
                }
                if (snake === 'bachelor' || snake === 'master' || snake === 'doctor') {
                    form.append(`${snake}_u_id`, this.profile[`${snake}_u_id`])
                    form.append(`${snake}_u_name`, this.profile[`${snake}_u_name`] || '')
                    form.append(`${snake}_c_id`, this.profile[`${snake}_c_id`])
                    form.append(`${snake}_c_name`, this.profile[`${snake}_c_name`] || '')
                    form.append(`${snake}_m_id`, this.profile[`${snake}_m_id`])
                    form.append(`${snake}_m_name`, this.profile[`${snake}_m_name`] || '')

                    form.append(`${snake}_start`, this.profile[`${snake}_start`])
                    form.append(`${snake}_end`, this.profile[`${snake}_end`])
                    form.append(`${snake}_status`, this.profile[`${snake}_status`])
                    return
                }

                if (snake === 'high_school') {
                    form.append('high_school_name', this.profile.high_school_name || '')
                    form.append('high_school_start', this.profile.high_school_start)
                    form.append('high_school_end', this.profile.high_school_end)
                    form.append('high_school_status', this.profile.high_school_status)
                    return
                }

                if (snake === 'family_relations') {
                    form.append('brother_count', this.profile.brother_count)
                    form.append('sister_count', this.profile.sister_count)
                    form.append('order_of_children', this.profile.order_of_children)
                    return
                }

                if (snake === 'health_status') {
                    form.append('health_status', this.profile.health_status)
                    form.append('health_detail', this.profile.health_detail || '')
                    return
                }

                if (snake === 'asset') {
                    form.append('asset', this.profile[snake])
                    if (this.profile.asset_detail) form.append('asset_detail', this.profile.asset_detail)
                    return
                }
                const validProfileKey = this.profile[snake] !== undefined
                if (validProfileKey) form.append(`${snake}`, this.profile[snake])
            })

            form.append('house_owner', this.profile.house_owner !== null ? this.profile.house_owner : '')

            if (this.profile.profile_options) {
                const profileOptionIds = this.profile.profile_options.map(po => po.profile_option_id)
                form.append('profile_option_ids', JSON.stringify(profileOptionIds))
            }

            form.append('company', JSON.stringify(this.profile.company))
            form.append('family', JSON.stringify(this.profile.family))
            form.append('privacy_setting', JSON.stringify(this.profile.privacy_setting))
            if (this.currentField.title === 'photo_upload') {
                this.me.photos
                    .filter(p => p.blob)
                    .forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))
                form.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))
            }
            return form
        },
        setInitialStage() {
            const profile = this.me.profile
            if (!profile.height) return 1
            if (!profile.station.name) return 2
            if (!profile.religion_name) return 3
            if (profile.smoking === null) return 4
            if (!profile.mbti) return 5
            if (!profile.school.name) return 6
            if (profile.school.name.includes('학사')) {
                if (!profile.bachelor_m_name) return 7
            }
            if (profile.school.name.includes('석사')) {
                if (!profile.bachelor_m_name) return 7
                if (!profile.master_m_name) return 9
            }
            if (profile.school.name.includes('박사')) {
                if (!profile.bachelor_m_name) return 7
                if (!profile.master_m_name) return 9
                if (!profile.doctor_m_name) return 11
            }
            if (!profile.company[0].job_category_name) return 13
            if (!profile.company[0].company_name) return 14
            if (!profile.company[0].job_name) return 15
            if (profile.company[0].show_employment_type === null) return 16
            if (!profile.company[0].company_state_name) return 17
            if (profile.income === null) return 18
            if (profile.asset === null) return 19
            if (!profile.marry_plan) return 20
            if (!profile.marry) return 21
            if (this.me.photos.length < 3) return 22
            return 23
        },
    },
}
</script>

<style scoped lang="scss"></style>
